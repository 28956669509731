import { DRAFT_STEP, DRAFT_STEP_ORDER } from '~/enums/DraftStep'

import type { DraftStepStatuses } from '~/types/draft'

export default defineNuxtRouteMiddleware((to) => {
  const { $localePath, $pinia } = useNuxtApp()
  const draftStore = useDraftStore($pinia)

  // should only perform a redirect when the route doesn't have a "step" query param
  // or isn't going directly to the catalog
  if (to.query?.step || to.path.includes('influencers')) return

  // Redirect to the correct step based on the draft step status
  const step = determineMissingStep(draftStore.step_status)
  const redirectPath = getStepPath(step, draftStore.id, $localePath)

  // if the user is already on the correct step, don't redirect
  if (redirectPath.includes(`step=${to.query?.step}`)) return

  return navigateTo(redirectPath)
})

/**
 * Figures out the furthest step that the user completed by checking for missing steps.
 * Note that even if a step is marked as done, if a previous step is missing,
 * it will return the step preceding the missing step.
 *
 * @param stepStatus - the step status array of the draft.
 * @returns the step the user should be on.
 */
function determineMissingStep(stepStatus: DraftStepStatuses[]) {
  const missingStep = DRAFT_STEP_ORDER.find(
    (step) => !stepStatus.includes(step),
  )
  return missingStep || DRAFT_STEP_ORDER[DRAFT_STEP_ORDER.length - 1]
}

function getStepPath(
  step: DraftStepStatuses,
  draftId: number | undefined,
  $localePath: ReturnType<typeof useNuxtApp>['$localePath'],
) {
  if (!draftId) return $localePath('/')

  const path = `/draft/${draftId}/`

  switch (step) {
    case DRAFT_STEP.TRACK:
      return $localePath({ path, query: { step: 0 } })
    case DRAFT_STEP.CURATORS:
      return $localePath({ path: `${path}influencers/` })
    case DRAFT_STEP.MESSAGE:
      return $localePath({ path, query: { step: 2 } })
    case DRAFT_STEP.RECAP:
      return $localePath({ path, query: { step: 3 } })
    default:
      return $localePath({ path, query: { step: 0 } })
  }
}
